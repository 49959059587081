import Logo from "../assets/img/logo-tribo-digital.svg";
import "./Header.sass";

export function Header() {
  const urlLinkedin =
    "https://www.linkedin.com/company/tribo-digital/?viewAsMember=true";

  return (
    <header>
      <a href="/" className="logo">
        <img src={Logo} alt="logo Tribo Digital" />
      </a>
      <a href={urlLinkedin} className="linkedin" target={"blank"}>
        Acompanhe no Linkedin
      </a>
    </header>
  );
}
