import "./App.sass";
import { Footer } from "./pages/Footer";
import { Header } from "./pages/Header";
import { Home } from "./pages/Home";

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <Footer />
    </div>
  );
}

export default App;
