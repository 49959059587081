import "./Footer.sass";

export function Footer() {
  return (
    <footer className="footer">
      <p className="copy">
        &copy; 2022 Tribo Digital.
        <span>Todos os direitos reservados.</span>
      </p>
    </footer>
  );
}
