import Imagem from "../assets/img/img-home.svg";
import IconWhatsApp from "../assets/img/icon-whatsapp.svg";
import "./Home.sass";

export function Home() {
  return (
    <>
      <section className="box1">
        <div className="text-container">
          <p className="small-text">Olá,</p>
          <p className="home-text">
            estamos criando um <span>novo site.</span>
          </p>
          <p className="description">
            Em breve estará tudo pronto. Enquanto isso, você pode entrar em
            contato com a gente.
          </p>
          <a href="https://wa.me/+5511949283061" target={"blank"}>
            <button className="whatsapp">
              <img
                src={IconWhatsApp}
                alt="WhatsApp"
                className="icon-whatsapp"
              />
              <span className="txt-btn">Falar no WhatsApp</span>
            </button>
          </a>
        </div>
        <div className="imagem-container">
          <img className="imagem" src={Imagem} alt="Imagem Home" />
        </div>
      </section>
    </>
  );
}
